// Initialise Slick slider

( function( $, window, document, undefined ) {

	const $slides = $('.h-carousel__slide');
	const $slide_count = $('.main-slide');
	const $buttons = $('.h-carousel__buttons').find('button');

	var autoplaySpeed = 5000;
	var swipeSpeed = 200;
	var current_slide = 1;

	// begin autoplay
	if ( $slides.length ) {
		var autoplay = setInterval( function() {
			slide('next');
		}, autoplaySpeed);
	}

	// nav controls
	$buttons.on('click', function() {
		nextSlide( $(this).attr('data-direction') );
	});
	    
    function slide(direction) {

    	var $active_slides = $('.slide-active');
    	var $next;

    	$active_slides.each(function() {

    		// determine next/prev slide along...
    		if ( direction == 'next' ) {
	    		$next = $(this).next('.h-carousel__slide');

	    		if ( $next.length == 0 ) {
	    			$next = $(this).parent().find($slides).first();
	    		}
    		} else {
    			$next = $(this).prev('.h-carousel__slide');

	    		if ( $next.length == 0 ) {
	    			$next = $(this).parent().find($slides).last();
	    		}
    		}

    		// ...and set it's z-index to be below the active slide
    		$next.addClass('next-slide');

    		// play swipe animation outro on current slide
    		$(this).addClass('hide-' + direction);

    		// restart the animated timer
    		restartTimer();
    	});

    	// allow some time for the swipe animation outro to play out
    	window.setTimeout(function () {

    		// determine the new active slide
    		if ( direction == 'next' ) {

		    	if ( current_slide >= $slide_count.length ) {
					current_slide = 1;
	    		} else {
	    			current_slide++;
	    		}

	    	}

	    	if ( direction == 'prev' ) {
	    		if ( current_slide == 1 ) {
					current_slide = $slide_count.length;
	    		} else {
	    			current_slide--;
	    		}
	    	}

    		// set active slide
	    	$slides.each(function() {
	    		$(this).removeClass('slide-active');

	    		if ( $(this).attr('data-slide') == current_slide ) {
	    			$(this).addClass('slide-active');
	    		}

	    		$(this).removeClass('hide-' + direction).removeClass('next-slide');
	    	});

    	}, swipeSpeed);

    }

    // navigate to next/prev slide and restart autoplay
    function nextSlide(direction) {
    	clearInterval(autoplay);
		slide(direction);
		autoplay = setInterval( function() {
			slide(direction);
		}, autoplaySpeed);
    }

    function restartTimer() {
    	$timer = $('.h-carousel__timer').find('span');
		$newtimer = $timer.clone(true);
		$timer.before($newtimer);
		$("." + $timer.attr("class") + ":last").remove();
    }
	
} )( jQuery, window, document );
