(function ($, window, document, undefined) {

	$('.your-solihull-slider').slick({
		infinite: true,
		speed: 2000,
		fade: true,
		cssEase: 'linear',
		autoplay: true,
		autoplaySpeed: 3000,
		arrows: false,
	});

})(jQuery, window, document);