/**
 * Open and close submenus in a11y friendly way.
 */

( function( $, window, document, undefined ) {
	
	let $toggle = $('.js-site-nav-toggle');
	let $site_nav = $('.site-nav');
	const disableBodyScroll = bodyScrollLock.disableBodyScroll;
	const enableBodyScroll = bodyScrollLock.enableBodyScroll;

	$toggle.off('click').on('click', function() {
		if ( $site_nav.hasClass('is-active') ) {
			closeMenu();
		} else {
			openMenu();
		}
	});

	function openMenu()
	{
		$site_nav.addClass('is-active');
		$toggle.addClass('is-active');
		disableBodyScroll($site_nav[0]);
	}

	function closeMenu()
	{
		$site_nav.removeClass('is-active');
		$toggle.removeClass('is-active');
		enableBodyScroll($site_nav[0]);
	}
	

} )( jQuery, window, document );
