( function( $, window, document, undefined ) {

	$map_toggle = $('.map-toggle');
	$footer = $('.site-footer');

	$(window).on('load scroll resize', function() {

		var top_of_element = $footer.offset().top;
	    var bottom_of_element = $footer.offset().top + $footer.outerHeight();
	    var bottom_of_screen = $(window).scrollTop() + $(window).innerHeight();
	    var top_of_screen = $(window).scrollTop();

	    if ((bottom_of_screen > top_of_element) && (top_of_screen < bottom_of_element)){
	        if ( !$map_toggle.hasClass('hide') ) {
	        	$map_toggle.addClass('hide');
	        }
	    } else {
	        if ( $map_toggle.hasClass('hide') ) {
	        	$map_toggle.removeClass('hide');
	        }
	    }

	});

} )( jQuery, window, document );