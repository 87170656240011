( function( $, window, document, undefined ) {

	$site_header = $('.site-header');
	$submenu = $('.submenu');
	$site_nav = $('.site-nav');
	$site_nav_list = $('.site-nav__list');
	$body = $('body');

	$(window).on('load scroll resize', function() {

		var top_of_menu = $site_nav.offset().top;
		var is_mobile = $('.is-mobile').css('display');

		// make sure submenu is aligned before sticky menu kicks in
		var header_height = $site_header.outerHeight();
		var top_of_screen = $(window).scrollTop();
		var nav_bottom = $site_nav_list.offset().top + $site_nav_list.outerHeight();
		var header_bottom = $site_header.offset().top + header_height;

		var header_height = $site_header.outerHeight();
		var submenu_top = header_height - top_of_screen;

		$submenu.css('top', submenu_top + 'px');

		if ( is_mobile == 'block' ) {

			if ( top_of_screen >= top_of_menu && !$site_nav.hasClass('is-sticky') ) {
				$body.addClass('has-sticky-menu');
				$site_nav.addClass('is-sticky');
			} else if ( nav_bottom <= header_bottom && $site_nav.hasClass('is-sticky') ) {
				$body.removeClass('has-sticky-menu');
				$site_nav.removeClass('is-sticky');
			}

		} else {
			if ( $site_nav.hasClass('is-sticky') ) {
				$body.removeClass('has-sticky-menu');
				$site_nav.removeClass('is-sticky');
			}
		}

	});

} )( jQuery, window, document );